<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div>
    <egw-table :retrieve-data="retrieveData" :after-retrieve="afterDataBackupInspectionLogList"
      :export-data-file="exportData" :export-file-name="exportFileName" :form-options="formOptions" :index="index"
      :columns="columns" :is-row-click="isRowClick" :info-title="infoTitle" :drawerform="drawerform"
      :info-title2="infoTitle2" :drawerform2="drawerform2" :param="params" :is-echart="isEchart"
      @handleLink="handleLink" />
  </div>
</template>

<script>
// 导入发送请求的方法
import { getDataBackupInspectionLogList, exportDataBackupInspectionLogsAsExcelFile } from '../api/ajax-data-backup-inspection-log'
export default {
  name: 'DataBackupInspectionLog',
  data() {
    return {
      retrieveData: getDataBackupInspectionLogList,
      exportData: exportDataBackupInspectionLogsAsExcelFile,
      // 导出的名称
      exportFileName: this.$t('export.dataBackupInspectionLogInfo'),
      // 显示序号
      index: true,
      // 点击某一行显示详情信息
      isRowClick: true,
      // 搜索
      formOptions: [
        {
          label: this.$t('dataBackupInspectionLog.label.status'), prop: 'status', type: 'select',
          selectOptions: [
            { value: 1, label: '正常' },
            { value: 2, label: '异常告警' }
          ]
        },
        {
          label: this.$t('dataBackupInspectionLog.label.inspectedTime'), type: 'date'
        }
      ],
      // 表格表头
      columns: [
        { prop: 'dataBackupId', label: this.$t('dataBackupInspectionLog.label.dataBackupId') },
        { prop: 'serverId', label: this.$t('dataBackupInspectionLog.label.serverId') },
        { prop: 'programId', label: this.$t('dataBackupInspectionLog.label.programId') },
        { prop: 'tenantId', label: this.$t('dataBackupInspectionLog.label.tenantId') },
        { prop: 'inspectedTime', label: this.$t('dataBackupInspectionLog.label.inspectedTime') },
        { prop: 'status', label: this.$t('dataBackupInspectionLog.label.status'), type: 'tag' },
        { prop: 'alarmType', label: this.$t('dataBackupInspectionLog.label.alarmType') }
      ],
      // 详情标题
      infoTitle: this.$t('dataBackupInspectionLog.title.dataBackupInfo'),
      // 详情信息的标题和label
      drawerform: [
        { prop: 'dataBackupId', label: this.$t('dataBackup.label.id') },
        { prop: 'serverId', label: this.$t('dataBackup.label.serverId') },
        { prop: 'programId', label: this.$t('dataBackup.label.programId') },
        { prop: 'sourcePath', label: this.$t('dataBackup.label.sourcePath') },
        { prop: 'destinationPath', label: this.$t('dataBackup.label.destinationPath') },
        { prop: 'frequencey', label: this.$t('dataBackup.label.frequencey') },
        { prop: 'registeredUserId', label: this.$t('dataBackup.label.registeredUserId') },
        { prop: 'registeredTime', label: this.$t('dataBackup.label.registeredTime') },
        { prop: 'updatedUserId', label: this.$t('dataBackup.label.updatedUserId') },
        { prop: 'updatedTime', label: this.$t('dataBackup.label.updatedTime') },
        { prop: 'inspectionPeriod', label: this.$t('dataBackup.label.inspectionPeriod') },
        { prop: 'remark', label: this.$t('dataBackup.label.remark') },
        { prop: 'inspectionConfig', label: this.$t('dataBackup.label.inspectionConfig'), span: 24, type: 'textarea' }
      ],
      // 第二个详情标题
      infoTitle2: this.$t('dataBackupInspectionLog.title.inspectionLogInfo'),
      // 第二个详情信息的标题和label
      drawerform2: [
        { prop: 'id', label: this.$t('dataBackupInspectionLog.label.id') },
        { prop: 'tenantId', label: this.$t('dataBackupInspectionLog.label.tenantId') },
        { prop: 'dataBackupId', label: this.$t('dataBackupInspectionLog.label.dataBackupId') },
        { prop: 'inspectedTime', label: this.$t('dataBackupInspectionLog.label.inspectedTime') },
        { prop: 'status', label: this.$t('dataBackupInspectionLog.label.status'), type: 'tag' },
        { prop: 'alarmType', label: this.$t('dataBackupInspectionLog.label.alarmType') },
        { prop: 'alarmMessage', label: this.$t('dataBackupInspectionLog.label.alarmMessage') }
      ],
      params: undefined,
      isEchart: false
    }
  },
  created() {
    console.log(this.$route.query)
    if (JSON.stringify(this.$route.query) === '{}') {
      console.log('路由没有参数')
    } else {
      this.params = this.$route.query
      console.log(this.params)
      this.formOptions = [{ label: this.$t('dataBackupInspectionLog.label.inspectedTime'), type: 'date' }]
    }
  },
  methods: {
    afterDataBackupInspectionLogList(list) {
      list.forEach(item => {
        item = this.changeStatus(item)
        item = this.changeAlarmType(item)
        item.inspectedTime = this.changeTime(item.inspectedTime)
      })
      // console.log(list)
      return list
    },
    /**
    * @methods changeStatus
    * @description 转换巡检状态为i18n对应的字段
    * @param {Object} params 包含巡检状态的数据
    */
    changeStatus(item) {
      console.log('item', item)
      if (item.status === 1) {
        item.status = this.$t('status.normal')
        item.type = ''
      } else if (item.status === 2) {
        item.status = this.$t('status.anomalous')
        item.type = 'danger'
      }
      return item
    },
    /**
    * @methods changeAlarmType
    * @description 将告警类型转换为对应i8n的字段
    * @param {Object} params 包含告警类型的数据
    */
    changeAlarmType(item) {
      if (item.alarmType === 'LAST_DATA_BACKUP_ABNORAML') {
        item.alarmType = this.$t('dataBackupInspectionLog.alarmType.lastDataBackup')
      }
      return item
    },
    handleLink(data) {
      const id = data.dataBackupId
      this.$router.replace({
        path: '/empty',
        query: {
          router: '/dataBackupInspectionLog?dataBackupId=' + id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
